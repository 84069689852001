import Script from 'next/script';

function Clarity() {
  const env = process.env.NEXT_PUBLIC_ENV || process.env.NODE_ENV;

  if(env !== 'production') return <></>;

  return (
    <div>
      <Script strategy="lazyOnload">
        {`
          (function(c,l,a,r,i,t,y){
            c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
            t=l.createElement(r);t.async=1;t.src='https://www.clarity.ms/tag/'+i;
            y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
          })(window, document, 'clarity', 'script', 'h7pmhymjye');
        `}
      </Script>
    </div>
  );
}

export default Clarity;
