import React from 'react';

export const MinicartWarningIcon = ({...props}) => {
  const color = props.color || '#FFA829'
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18" fill="none" {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M7.96798 1.16592C8.85365 -0.388639 11.1464 -0.388642 12.032 1.16592L19.7041 14.6324C20.5649 16.1433 19.4445 18 17.6721 18H2.32789C0.555459 18 -0.564896 16.1433 0.29587 14.6324L7.96798 1.16592ZM10.9999 14C10.9999 14.5523 10.5522 15 9.99994 15C9.44765 15 8.99994 14.5523 8.99994 14C8.99994 13.4477 9.44765 13 9.99994 13C10.5522 13 10.9999 13.4477 10.9999 14ZM10.7499 6C10.7499 5.58579 10.4142 5.25 9.99994 5.25C9.58573 5.25 9.24994 5.58579 9.24994 6V11C9.24994 11.4142 9.58573 11.75 9.99994 11.75C10.4142 11.75 10.7499 11.4142 10.7499 11V6Z" fill={color}/>
    </svg>
  );
};
