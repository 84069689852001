import { API_ENDPOINTS } from '@framework/utils/endpoints';
import { useQuery } from 'react-query';
import { NotificationsService } from "@framework/notifications/notifications.service";
import _ from 'lodash';
import { NotificationsPaginationQueryOptionsType } from '@framework/types';
import { mapPaginatorData } from '@framework/utils/data-mappers';
import { useMutation } from "react-query";

export const fetchAllNotifications = async (params: NotificationsPaginationQueryOptionsType) => {
  const response = await NotificationsService.all(params);

  const { data, ...rest } = response || {};

  return { notifications: data, paginatorInfo: mapPaginatorData({ ...rest })};
}

export const useAllNotificationsQuery = (
  params: NotificationsPaginationQueryOptionsType,
  options?: any
) => {
  return useQuery<any, Error>([API_ENDPOINTS.NOTIFICATIONS, params], () =>
    fetchAllNotifications(params), { ...options }
  );
};

export const fetchLastestNotifications = async (params: NotificationsPaginationQueryOptionsType) => {
  const response = await NotificationsService.getLastestNotifications(params);

  const { data, ...rest } = response || {};

  return { notifications: data, paginatorInfo: mapPaginatorData({ ...rest })};
}

export const useLastestNotificationsQuery = (
  params: NotificationsPaginationQueryOptionsType,
  options?: any
) => {
  return useQuery<any, Error>([API_ENDPOINTS.LASTEST_NOTIFICATION, params], () =>
    fetchLastestNotifications(params), {
      staleTime: Infinity,
      ...options
    }
  );
};

export const fetchTotalNotifications = async () => {
  const response = await NotificationsService.getTotalNotifications();

  return response
}

export const useTotalNotificationsQuery = (
  options?: any
) => {
  return useQuery<any, Error>([API_ENDPOINTS.GET_TOTAL_NOTIFICATIONS], () =>
    fetchTotalNotifications(), { ...options }
  );
};

export const fetchNotificationReadIds = async () => {
  const response = await NotificationsService.getNotificationReadIds();

  return response
}

export const useNotificationReadIdsQuery = (
  options?: any
) => {
  return useQuery<any, Error>(API_ENDPOINTS.NOTIFICATION_READ_IDS, () =>
    fetchNotificationReadIds(), { ...options, staleTime: Infinity }
  );
};

export const useDeleteNotificationMutation = () => {
  return useMutation((input: { id: number | string }) => NotificationsService.deleteNotification(input));
}

export const useDeleteAllNotificationMutation = () => {
  return useMutation(() => NotificationsService.deleteAllNotification());
}

export const useReadNotificationMutation = () => {
  return useMutation((input: { id: number | string }) => NotificationsService.readNotification(input));
}

export const useReadAllNotificationMutation = () => {
  return useMutation(() => NotificationsService.readAllNotification());
}
