type GoToTopIconProps = {
  width?: number;
  height?: number;
  className?: string;
};

const GoToTopIcon: React.FC<GoToTopIconProps> = ({
  width = 15,
  height = 25,
  className,
}) => {
  return (
    <svg width={width} height={height} className={className} viewBox="0 0 15 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.5 1.5L7.5 23.5M7.5 1.5L13.6875 7.6875M7.5 1.5L1.3125 7.6875" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
    </svg>
  );
};

export default GoToTopIcon;
