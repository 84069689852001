import { CoreApi } from '@framework/utils/core-api';
import { API_ENDPOINTS } from '@framework/utils/endpoints';
import { NotificationsPaginationQueryOptionsType } from '@framework/types';

class Notifications extends CoreApi {
  constructor(_base_path: string) {
    super(_base_path);
  }

  all(params: NotificationsPaginationQueryOptionsType) {
    const { page, limit, type, locale } = params;
    return this.http.get(this._base_path, {
      params: {
        page: page || 1,
        limit: limit || API_ENDPOINTS.DEFAULT_PRODUCTS_PER_PAGE,
        type: type || '',
      }
    })
    .then((res) => res.data)
    .catch(() => []);
  }

  getTotalNotifications() {
    return this.http.get(API_ENDPOINTS.GET_TOTAL_NOTIFICATIONS)
    .then((res) => res.data)
    .catch(() => 0);
  }

  getLastestNotifications(params: NotificationsPaginationQueryOptionsType) {
    const { limit, locale } = params;

    return this.http.get(API_ENDPOINTS.LASTEST_NOTIFICATION, {
      params: {
        limit: limit || 10,
      }
    })
    .then((res) => res.data)
    .catch(() => []);
  }

  getNotificationReadIds() {
    return this.http.get(API_ENDPOINTS.NOTIFICATION_READ_IDS)
    .then((res) => res.data)
    .catch(() => []);
  }

  deleteNotification(input: { id: number | string }) {
    return this.http.delete(API_ENDPOINTS.DELETE_NOTIFICATION, {
      params: { notification_id: input.id }
    })
    .then((res) => res.data)
  }

  deleteAllNotification() {
    return this.http.delete(API_ENDPOINTS.DELETE_ALL_NOTIFICATION)
    .then((res) => res.data)
  }

  readNotification(input: { id: number | string }) {
    return this.http.post(API_ENDPOINTS.READ_NOTIFICATION,
      { notification_id: input.id }
    )
    .then((res) => res.data)
  }

  readAllNotification() {
    return this.http.post(API_ENDPOINTS.READ_ALL_NOTIFICATION)
    .then((res) => res.data)
  }
}

export const NotificationsService = new Notifications(API_ENDPOINTS.NOTIFICATIONS);
