import React, { FC } from 'react';

type CheckProps = {
	width?: string;
	height?: string;
	className?: string;
	stroke?: string;
};

const Check: FC<CheckProps> = ({ width = "16", height = "16", stroke = "#6AA543", className}) => {
	return (
		<svg
			width={width}
			height={height}
			className={className}
			viewBox="0 0 16 16"
			fill="none" xmlns="http://www.w3.org/2000/svg"
		>
		<path
			d="M13.3332 4.6665L5.99991 11.9998L2.6665 8.6665"
			stroke={stroke} strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		</svg>
	);
};

export default Check;
